import { useContext, useEffect, useRef } from 'react';
import { Box } from 'components/atoms';
import { PATH } from 'paths';
import { useLocation } from 'react-router-dom';
import { MapContext } from 'contexts/MapContext';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { AuthContext } from 'contexts/AuthContext';
import { API_URL, IFRAME_TOKEN } from 'utils/envUtils';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';

const RoofIframe = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null) as any;
    const path = useLocation().pathname;
    const [calculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);
    const { setGetIframeDataFunction, setIsIframeDone, setIsPanelsExist } = useContext(MapContext);
    const { user } = useContext(AuthContext);
    const token = user?.token || IFRAME_TOKEN;
    const roofSection = path.split('/').includes(PATH.ROOF);

    useEffect(() => {
        if (iframeRef?.current?.contentWindow?.stage && roofSection) {
            setStageForPage();
        }
        // eslint-disable-next-line
    }, [path]);

    const setStageForPage = () => {
        console.log('🚀 ~ setStageForPage ~ setStage:', 'setStageForPage');
        if (path.endsWith(PATH.MARKING)) {
            setStage(1);
        } else if (path.endsWith(PATH.PLACEMENT_ELEMENTS)) {
            setStage(2);
        } else if (path.endsWith(PATH.TYPE)) {
            setStage(3);
        }
    };

    const setStage = (stage: number) => {
        console.log('🚀 ~ setStage ~ stage:', stage);
        iframeRef.current.contentWindow.stage = stage;
        iframeRef?.current?.contentWindow?.updateStage();
    };

    const handleMessage = (event: any) => {
        if (event.detail) {
            setStageForPage();
            setGetIframeDataFunction(() => () => {
                const isPanelsExist = iframeRef?.current?.contentWindow?.done(
                    (value: { status: string; item: { panels_number: string } }) => {
                        if (value.status === 'ok' && +value.item.panels_number > 0) {
                            setIsIframeDone(true);
                        }
                    }
                );
                if (isPanelsExist) {
                    setIsPanelsExist(true);
                } else {
                    setIsPanelsExist(false);
                }
            });
        }
    };

    useEffect(() => {
        document.addEventListener('iframeSetup', handleMessage, false);
        return () => {
            document.removeEventListener('iframeSetup', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box width='100%' height='100%'>
            <iframe
                key={token}
                ref={iframeRef}
                frameBorder='0'
                width='100%'
                height='100%'
                src={`${process.env.PUBLIC_URL}/iframe/roof/index.html#url=${API_URL}&key=${token}&project=${calculator?.projectId}`}
                title='organuzroof'
            ></iframe>
        </Box>
    );
};

export default RoofIframe;
